import {Link} from 'react-router-dom';
import {useState,useEffect} from 'react'
import {MarketPlace, colapse,to4Digits} from '../shared.js'
import HistoryAll from './HistoryAll.jsx';
import ModalSubmitted from './shared/ModalSubmitted.jsx';
import {doOfferTx} from './shared/Functions.jsx';
import ModalOffer from './shared/ModalOffer.jsx';
import {buyTx} from './shared/Functions.jsx';
import {Helmet} from "react-helmet";

function Punk({address,lucid,emptyWallet}) {

  const [allSales, setAllSales] = useState({page:0,assets:[]});
  const [punkInfo, setPunkInfo] = useState(null);
  const [offerModal, setOfferModal] = useState(false);
  const [modalEnabled, setModalEnabled] = useState(true);
  const params = new URLSearchParams(window.location.search);
  const [txId, setTxId] = useState('');
  //To disable the buy/fight modal button when wallet singning
  const [submittedModal, setSubmittedModal] = useState(false);

  function offer(){
    setOfferModal(true);
    setModalEnabled(true);
  }


  async function doOffer(total){
    const txid= await doOfferTx(total,address,lucid,setModalEnabled,punkInfo.name);
    if (txid!=null){
      setTxId(txid);
      setOfferModal(false);
      setSubmittedModal(true);
    }
  }

  async function buy(){
    const txid = await buyTx(punkInfo.listedInfo,address,lucid);

    if (txid!=null){
      setTxId(txid);
      setSubmittedModal(true);
    }else{

    }
  }


  useEffect(() => {
    window.$.ajax({
      url: 'getPunkInfo',
      type: 'GET',
      data: {id: params.get("id")},
      dataType: "json",
      success: function (data) {
        setPunkInfo(data);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    });
  }, []);



  var attrs = "";
  if (punkInfo!=null){
  for (const att of punkInfo.attributes)
    attrs+="<h3 style='padding-left:15px;' className='text-body-emphasis'>"+att+"</h3>";
  }else{
    attrs+="<span className=\"placeholder col-8\"/>"
  }

  window.scrollTo(0, 0);




  return (
    <>
    <div className="container">
      <div className='row mb-5' >
        <div className='col-12 col-lg-6'>
            <img className='rounded-3 img-fluid' src={'nftImg?id='+params.get("id")}/>
        </div>
        <div className='col-12 col-lg-6'>
            <h1 className='text-center pt-3' style={{color:'#ffda6a'}}>{punkInfo==null?<div className='placeholder-glow'><span className="placeholder col-8"/></div>:punkInfo.name}</h1>
            <label className='text-body-emphasis m-3'>Owner: </label>{(punkInfo==null?<span className="placeholder col-8"/>:<Link onClick={emptyWallet} to={'/wallet/punks?addr='+punkInfo.owner}>{colapse(punkInfo.owner)}</Link>)}
            <div className='bg-body border-bottom shadow-sm p-3 rounded-top-3'>
                <div className='row'>
                    <div className='col'><label>Rank:</label><h3 style={{paddingLeft:'15px'}} className='text-body-emphasis'>{punkInfo==null?<div className='placeholder-glow'><span className="placeholder col-8"/></div>:to4Digits(punkInfo.rank)}</h3></div>
                    <div className='col'><label>Skin:</label><h3 style={{paddingLeft:'15px'}} className='text-body-emphasis'>{punkInfo==null?<div className='placeholder-glow'><span className="placeholder col-8"/></div>:punkInfo.skin}</h3></div>
                    <div className='col'><label>Type:</label><h3 style={{paddingLeft:'15px'}} className='text-body-emphasis'>{punkInfo==null?<div className='placeholder-glow'><span className="placeholder col-8"/></div>:punkInfo.type}</h3></div>
                </div>
            </div>
            <div className='bg-body shadow-sm p-3 rounded-bottom-3'>
                <div><div>Attributes:</div>
                {
                punkInfo==null?
                <div className='placeholder-glow'><h3 style={{paddingLeft:'15px'}} className='text-body-emphasis'><span className="placeholder col-6"/></h3><h3 style={{paddingLeft:'15px'}} className='text-body-emphasis'><span className="placeholder col-10"/></h3><h3 style={{paddingLeft:'15px'}} className='text-body-emphasis'><span className="placeholder col-3"/></h3></div>:
                punkInfo.attributes.map((att)=> <h3 key={att} style={{paddingLeft:'15px'}} className='text-body-emphasis'>{att}</h3>)
                }
                </div>
            </div>
            {punkInfo!=null && punkInfo.listedInfo!=null && <button className='btn btn-primary col-12 mt-4 p-3' type='button' onClick={buy}>{'Buy for '+punkInfo.listedInfo.amount+' ADA'}</button>}
            {punkInfo!=null && <button className='btn btn-primary col-12 mt-4 p-3' type='button' onClick={offer}>Set offer</button>}
        </div>
        </div>
    </div>
    {punkInfo!=null && <HistoryAll address={address} number={punkInfo!=null && punkInfo.number} emptyWallet={() => emptyWallet()} data={allSales} setData={setAllSales}/>}
    {submittedModal && <ModalSubmitted asset={punkInfo} hide={() => setSubmittedModal(false)} txId={txId}/>}
    {offerModal && <ModalOffer asset={punkInfo} hide={() => setOfferModal(false)} doOffer={doOffer} enabled={modalEnabled}/>}
    {punkInfo!=null && 
      <Helmet>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content={punkInfo.name+" | adapunks.io "}/>
        <meta property="og:description" content={punkInfo.name}/>
        <meta property="og:image" content={'https://adapunks.io/nftImg?id='+params.get("id")}/>
        <meta property="og:url" content={'https://adapunks.io/punk?id='+params.get("id")}/>
        <meta property="og:image:alt" content={punkInfo.name}/>
      </Helmet>
    }
    </>
  );
}

export default Punk;